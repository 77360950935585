<template>
  <div class="params">
    <div class="table-btns">
<!--      <a-icon type="redo" class="after-btn table-refresh" @click="getParamsConfig" style="float: right; margin-right: 6.1%;margin-left: 1%;margin-top: 1.5%;font-size: 20px"></a-icon>-->
<!--      <a-button type="primary" size="large" @click="getOrSet" style="float: right;margin-top: 1%">-->
<!--        {{status ?'下发当前参数':'获取最新参数' }}</a-button>-->
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
<!--      <a-form-model-item label="客户端ID" prop="clientId">-->
<!--        <a-input v-model="mqttConfigs.clientId" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
    </a-form-model>
    <a-form-model-item v-if="MqttFlag.mqttFlag==1" label="后台服务配置" prop="host" style="margin-left: 11.5%"></a-form-model-item>
    <a-form-model v-if="MqttFlag.mqttFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item  label="后台IP" prop="ip">
        <a-input v-model="mqttConfigs.ip" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="后台端口" prop="port">
        <a-input v-model="mqttConfigs.port" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="用户名" prop="user">
        <a-input v-model="mqttConfigs.user" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="密码" prop="password">
        <a-input type="password" v-model="mqttConfigs.password" placeholder="" ></a-input>
      </a-form-model-item>
      <a-form-model-item  label="Tls" prop="tls">
        <a-input v-model="mqttConfigs.tls" placeholder="" ></a-input>
      </a-form-model-item>
    </a-form-model>
    <a-form-model v-if="MqttFlag.mqttFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns"  :label-col="{span:9}" :wrapper-col="{span:14}" style="margin-top: 30px">
    <a-form-model-item label="secret" prop="secret">
      <a-input v-model="mqttConfigs.secret" placeholder=""></a-input>
    </a-form-model-item>
    <a-form-model-item label="key" prop="key">
      <a-input v-model="mqttConfigs.key" placeholder=""></a-input>
    </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {getDeviceparamChildLisByParentId, getDeviceparamValueList, setDeviceparamValueList} from "A/ai";
export default {
  name: "mqtt",
  props:{
    MqttFlag:{
      default: null
    }
  },
  data() {
    return {
      mqttConfigs:{
        clientId:'',
        ip:'mqtt-mainserver.u-lake.com',
        port:'1886',
        user:'admin',
        password:'public',
        tls:'0',
        secret:'147a2cc7130f4fd8a81783915d342a27',
        key:'88b179bdaa794f0e93736a37dda2cf34',
      },
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      methodList: [],
      tabKey: '404',
      status:false,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getParamsConfig() {
      // this.paramsList = [];
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'GET',
                url: '/smart/netwok/mqttclient?service=servicemqttclient&action=getconfig',
              }
            }
          ]
        };
        getDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
            this.mqttConfigs.clientId=res.item[0].params.body.clientId
            this.mqttConfigs.ip=res.item[0].params.body.host.ip
            this.mqttConfigs.port=res.item[0].params.body.host.port
            this.mqttConfigs.user=res.item[0].params.body.host.user
            this.mqttConfigs.password=res.item[0].params.body.host.password
            this.mqttConfigs.tls=res.item[0].params.body.host.tls
            this.mqttConfigs.secret=res.item[0].params.body.secret
            this.mqttConfigs.key=res.item[0].params.body.key
          }
        }).catch(err => {
          this.hideLoading();
        })
      // }
    },
    setParamsConfig(){
      // let selectedMethod = getItemFromArrayByKey(this.methodList, 'devparamcfgid', this.tabKey);
      // if(selectedMethod) {
        let paramsBody = {
         clientId : this.mqttConfigs.clientId,
          host:{
            ip : this.mqttConfigs.ip,
            port : Number(this.mqttConfigs.port),
            user : this.mqttConfigs.user,
            password : this.mqttConfigs.password,
            tls : Number(this.mqttConfigs.tls),
          },
         secret : this.mqttConfigs.secret,
         key : this.mqttConfigs.key,
        };
        // console.log(paramsBody);
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              modeltype: this.devicemoduletype,
              modelnum: this.modelnum,
              params: {
                methord: 'PUT',
                url:'/smart/netwok/mqttclient?service=servicemqttclient&action=setconfig',
                body: paramsBody,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            this.$message.success('操作成功');
          }
        }).catch(err => {
          this.hideLoading();
          this.$message.error('操作失败');
        })
      // }
    },
    getOrSet(){
      if(this.status){
        this.setParamsConfig();
        this.status=false;
      }else{
        this.getParamsConfig();
        this.status=true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>