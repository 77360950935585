<template>
  <div class="params">
    <div class="table-btns">
      <a-button type="primary" size="large" @click="setParams" style="float: right;margin-right:3.6%;margin-top: 1%">
        {{'下发当前参数'}}</a-button>
    </div>
    <a-form-model v-if="VideoAiFlag.videoAiFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="控门不关时间" prop="heartbeat_cycle">
        <a-input v-model="aiConfigs.ev_event_door_time" placeholder="默认30s"></a-input>
      </a-form-model-item>
      <a-form-model-item label="控制电动车不关门使能标志" prop="heartbeat_cycle">
        <a-input v-model="aiConfigs.ev_event_door_enable" placeholder="默认0，不使能"></a-input>
      </a-form-model-item>
      <a-form-model-item label="阻门识别AI事件是否启用开关" prop="block_event_enable">
        <a-input v-model="aiConfigs.block_event_enable" placeholder=""></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="电动车事件解除标志" prop="ev_event_cancel">-->
<!--        <a-input v-model="aiConfigs.ev_event_cancel" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="电动车识别AI事件是否启用开关" prop="ev_event_enable">
        <a-input v-model="aiConfigs.ev_event_enable" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车识别置信值阀值" prop="INIT_WITH_VEHICLE_THRESHOLD">
        <a-input v-model="aiConfigs.INIT_WITH_VEHICLE_THRESHOLD" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车头识别置信值阀值" prop="INIT_WITH_VEHICLE_HEAD_THRESHOLD">
        <a-input v-model="aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车座识别置信值阀值" prop="INIT_WITH_VEHICLE_CHAIR_THRESHOLD">
        <a-input v-model="aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="录像文件上传使能" prop="UPLOAD_VIDEO_ENABLE">
        <a-select v-model="aiConfigs.UPLOAD_VIDEO_ENABLE" placeholder="1使能，0禁用，默认使能">
          <a-select-option :value="1">使能</a-select-option>
          <a-select-option :value="0">禁用</a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>


    <a-form-model v-if="VideoAiFlag.videoAiFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="ftp地址" prop="ftp_host">
        <a-input v-model="aiConfigs.ftp_host" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="ftp端口号" prop="heartbeat_cycle">
        <a-input v-model="aiConfigs.ftp_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="ftp用户名" prop="ftp_user_name">
        <a-input v-model="aiConfigs.ftp_user_name" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="ftp密码" prop=" ftp_user_passwd">
        <a-input type="password" v-model="aiConfigs.ftp_user_passwd" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="监听端口" prop="LISTEN_PORT">
        <a-input v-model="aiConfigs.LISTEN_PORT" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="mqtt发送端口" prop="mqtt_port">
        <a-input v-model="aiConfigs.mqtt_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="打架识别置信值阀值" prop="INIT_WITH_FIGHT_THRESHOLD">
        <a-input v-model="aiConfigs.INIT_WITH_FIGHT_THRESHOLD" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="手碰头识别置信值阀值" prop="INIT_WITH_FISTHEAD_THRESHOLD">
        <a-input v-model="aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车事件队列长度" prop="VEHICLE_QUEUE_SIZE">
        <a-input v-model="aiConfigs.VEHICLE_QUEUE_SIZE" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车事件个数阀值" prop="VEHICLE_QUEUE_NUM">
        <a-input v-model="aiConfigs.VEHICLE_QUEUE_NUM" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车头和车座事件队列长度" prop="VEHICLE_CHAIR_HEAD_SIZE">
        <a-input v-model="aiConfigs.VEHICLE_CHAIR_HEAD_SIZE" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="电动车头和车座事件个数阈值" prop="VEHICLE_CHAIR_HEAD_NUM">
        <a-input v-model="aiConfigs.VEHICLE_CHAIR_HEAD_NUM" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="打架事件队列长度" prop="FIGHT_QUEUE_SIZE">
        <a-input v-model="aiConfigs.FIGHT_QUEUE_SIZE" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="打架事件个数阀值" prop="FIGHT_QUEUE_NUM">
        <a-input v-model="aiConfigs.FIGHT_QUEUE_NUM" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="手碰头事件队列长度" prop="FISTHEAD_QUEUE_SIZE">
        <a-input v-model="aiConfigs.FISTHEAD_QUEUE_SIZE" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="手碰头事件个数阀值" prop="FISTHEAD_QUEUE_NUM">
        <a-input v-model="aiConfigs.FISTHEAD_QUEUE_NUM" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="设备温度阀值" prop="temp_threshold">
        <a-input v-model="aiConfigs.temp_threshold" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="开关门区域判断位置设置" prop="door_area">
        <a-input v-model="aiConfigs.door_area" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="打架识别AI事件是否启用开关" prop="abnormal_event_enable">
        <a-input v-model="aiConfigs.abnormal_event_enable" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="手碰头识别AI事件是否启用开关" prop="fisthead_enable">
        <a-input v-model="aiConfigs.fisthead_enable" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="与物联网程序内部通信端口" prop="iot_port">
        <a-input v-model="aiConfigs.iot_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="有人队列长度" prop="PERSON_STATUS_LIST_MAXLEN">
        <a-input v-model="aiConfigs.PERSON_STATUS_LIST_MAXLEN" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="有人确认阈值" prop="PERSON_STATUS_LIST_NUM">
        <a-input v-model="aiConfigs.PERSON_STATUS_LIST_NUM" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="开门队列长度" prop="DOOR_STATUS_LIST_MAXLEN">
        <a-input v-model="aiConfigs.DOOR_STATUS_LIST_MAXLEN" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="开门确认阈值" prop="DOOR_STATUS_LIST_NUM">
        <a-input v-model="aiConfigs.DOOR_STATUS_LIST_NUM" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="AI心跳周期" prop="heartbeat_cycle">
        <a-input v-model="aiConfigs.heartbeat_cycle" placeholder=""></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDevicegroupdevListByCondition, getDevicegroupListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import {getCache} from "U/index";
import moment from "moment";
export default {
  name: "videoAi",
  props:{
    VideoAiFlag:{
      default: null
    }
  },
  data() {
    return {
      aiConfigs:{
        heartbeat_cycle:'',
        INIT_WITH_VEHICLE_THRESHOLD:'',
        INIT_WITH_VEHICLE_HEAD_THRESHOLD:'',
        INIT_WITH_VEHICLE_CHAIR_THRESHOLD:'',
        INIT_WITH_FIGHT_THRESHOLD:'',
        INIT_WITH_FISTHEAD_THRESHOLD:'',
        VEHICLE_QUEUE_SIZE:'',
        VEHICLE_QUEUE_NUM:'',
        VEHICLE_CHAIR_HEAD_SIZE:'',
        VEHICLE_CHAIR_HEAD_NUM:'',
        FIGHT_QUEUE_SIZE:'',
        FIGHT_QUEUE_NUM:'',
        FISTHEAD_QUEUE_SIZE:'',
        FISTHEAD_QUEUE_NUM:'',
        temp_threshold:'',
        door_area:'',
        ev_event_enable:'',
        ev_event_cancel:'',
        abnormal_event_enable:'',
        fisthead_enable:'',
        block_event_enable:'',
        LISTEN_PORT:'',
        mqtt_port:'',
        iot_port:'',
        PERSON_STATUS_LIST_MAXLEN:'',
        PERSON_STATUS_LIST_NUM:'',
        DOOR_STATUS_LIST_MAXLEN:'',
        DOOR_STATUS_LIST_NUM:'',
        Ev_event_code:'',
        Ev_event_cancle_code:'',
        Block_event_code:'',
        Block_event_cancle_code:'',
        Abnormal_event_code:'',
        Abnormal_event_cancle_code:'',
        ev_event_door_enable:'',
        ev_event_door_time:'',
        ftp_host:'',
        ftp_port:'',
        ftp_user_name:'',
        ftp_user_passwd:'',
        UPLOAD_VIDEO_ENABLE:'',
      },
      paramsdata:'',
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      methodList: [],
      tabKey: '44',
      status:false,
      devicegroupid:'',
      devicegroupname:'',
      devicenums:[],
      devicenames:[],
      result:0,
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.devicegroupid = this.$route.params.devicegroupid||'';
      this.getDevicenum(this.devicegroupid);
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    getDevicenum(devicegroupid){
      let params = {
        devicegroupid: devicegroupid,
      }
      getDevicegroupdevListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          for(var i=0;i<res.item.length;i++){
            this.devicenums.push(res.item[i].devicenum)
            this.devicenames.push(res.item[i].devicename)
            this.devicegroupname=res.item[0].devicegroupname
          }
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '44';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    updateParams(){
      let param = {
        paramsid:this.parentid,
        orderresult:(this.result / this.devicenums.length * 100).toFixed(2) + "%",
      }
      modifyParamsBatchLog(param)
    },
    setParams(){
      let param = {
        username:this.username,
        paramsobj:this.devicegroupname,
        paramsdate:moment(new Date()).format("YYYYMMDDHHmmss"),
        devicenum:"",
        devicegroupid:"0",
        orderresult:"0.00%",
        paramstype:'0',
      }
      addParamsBatchLog(param).then(res=>{
        if(res&&res.returncode=='0'){
          this.result=0;
          this.parentid=res.item[0].paramsid
          for(var i=0;i<this.devicenums.length;i++){
            this.setParamsConfig(this.devicenums[i],this.devicenames[i],res.item[0].paramsid)
          }
          this.$message.success("操作成功")
        }
      })
    },
    setParamsConfig(devicenum,devicename,parentid) {
      this.paramsdata='';
      let paramsBody = {};
      if (this.VideoAiFlag.videoAiFlag == 0) {
        paramsBody = {
          ev_event_door_time: this.aiConfigs.ev_event_door_time,
          ev_event_door_enable: this.aiConfigs.ev_event_door_enable,
          block_event_enable: this.aiConfigs.block_event_enable,
          ev_event_enable: this.aiConfigs.ev_event_enable,
          INIT_WITH_VEHICLE_THRESHOLD: this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD,
          INIT_WITH_VEHICLE_HEAD_THRESHOLD: this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD,
          INIT_WITH_VEHICLE_CHAIR_THRESHOLD: this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD,
          UPLOAD_VIDEO_ENABLE: this.aiConfigs.UPLOAD_VIDEO_ENABLE,
        }
        if (this.aiConfigs.ev_event_door_time == null || this.aiConfigs.ev_event_door_time == "") {this.$delete(paramsBody, 'ev_event_door_time')}else{this.paramsdata=this.paramsdata+"控门不关时间,"+this.aiConfigs.ev_event_door_time+","}
        if (this.aiConfigs.ev_event_door_enable == null || this.aiConfigs.ev_event_door_enable == "") {this.$delete(paramsBody, 'ev_event_door_enable')}else{this.paramsdata=this.paramsdata+"控制电动车不关门使能标志,"+this.aiConfigs.ev_event_door_enable+","}
        if (this.aiConfigs.block_event_enable == null || this.aiConfigs.block_event_enable == "") {this.$delete(paramsBody, 'block_event_enable')}else{this.paramsdata=this.paramsdata+"阻门识别AI事件是否启用开关,"+this.aiConfigs.block_event_enable+","}
        if (this.aiConfigs.ev_event_enable == null || this.aiConfigs.ev_event_enable == "") {this.$delete(paramsBody, 'ev_event_enable')}else{this.paramsdata=this.paramsdata+"电动车识别AI事件是否启用开关\n,"+this.aiConfigs.ev_event_enable+","}
        if (this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD == null || this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD == "") {this.$delete(paramsBody, 'INIT_WITH_VEHICLE_THRESHOLD')}else{this.paramsdata=this.paramsdata+"电动车识别置信值阀值,"+this.aiConfigs.INIT_WITH_VEHICLE_THRESHOLD+","}
        if (this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD == null || this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD == "") {this.$delete(paramsBody, 'INIT_WITH_VEHICLE_HEAD_THRESHOLD')}else{this.paramsdata=this.paramsdata+"电动车头识别置信值阀值,"+this.aiConfigs.INIT_WITH_VEHICLE_HEAD_THRESHOLD+","}
        if (this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD == null || this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD == "") {this.$delete(paramsBody, 'INIT_WITH_VEHICLE_CHAIR_THRESHOLD')}else{this.paramsdata=this.paramsdata+"电动车座识别置信值阀值,"+this.aiConfigs.INIT_WITH_VEHICLE_CHAIR_THRESHOLD+","}
        if (this.aiConfigs.UPLOAD_VIDEO_ENABLE == null || this.aiConfigs.UPLOAD_VIDEO_ENABLE == "") {this.$delete(paramsBody, 'UPLOAD_VIDEO_ENABLE')}else{this.paramsdata=this.paramsdata+"上传录像使能,"+this.aiConfigs.UPLOAD_VIDEO_ENABLE+","}
      } else {
        paramsBody = {
          heartbeat_cycle: this.aiConfigs.heartbeat_cycle,
          version_number: this.aiConfigs.version_number,
          INIT_WITH_FIGHT_THRESHOLD: this.aiConfigs.INIT_WITH_FIGHT_THRESHOLD,
          INIT_WITH_FISTHEAD_THRESHOLD: this.aiConfigs.INIT_WITH_FISTHEAD_THRESHOLD,
          VEHICLE_QUEUE_SIZE: this.aiConfigs.VEHICLE_QUEUE_SIZE,
          VEHICLE_QUEUE_NUM: this.aiConfigs.VEHICLE_QUEUE_NUM,
          VEHICLE_CHAIR_HEAD_SIZE: this.aiConfigs.VEHICLE_CHAIR_HEAD_SIZE,
          VEHICLE_CHAIR_HEAD_NUM: this.aiConfigs.VEHICLE_CHAIR_HEAD_NUM,
          FIGHT_QUEUE_SIZE: this.aiConfigs.FIGHT_QUEUE_SIZE,
          FIGHT_QUEUE_NUM: this.aiConfigs.FIGHT_QUEUE_NUM,
          FISTHEAD_QUEUE_SIZE: this.aiConfigs.FISTHEAD_QUEUE_SIZE,
          FISTHEAD_QUEUE_NUM: this.aiConfigs.FISTHEAD_QUEUE_NUM,
          BLOCK_DOOR_LIST_MAXLEN: this.aiConfigs.BLOCK_DOOR_LIST_MAXLEN,
          BLOCK_DOOR_LIST_NUM: this.aiConfigs.BLOCK_DOOR_LIST_NUM,
          temp_threshold: this.aiConfigs.temp_threshold,
          door_area: this.aiConfigs.door_area,
          abnormal_event_enable: this.aiConfigs.abnormal_event_enable,
          fisthead_enable: this.aiConfigs.fisthead_enable,
          LISTEN_PORT: this.aiConfigs.LISTEN_PORT,
          mqtt_port: this.aiConfigs.mqtt_port,
          iot_port: this.aiConfigs.iot_port,
          PERSON_STATUS_LIST_MAXLEN: this.aiConfigs.PERSON_STATUS_LIST_MAXLEN,
          PERSON_STATUS_LIST_NUM: this.aiConfigs.PERSON_STATUS_LIST_NUM,
          DOOR_STATUS_LIST_MAXLEN: this.aiConfigs.DOOR_STATUS_LIST_MAXLEN,
          DOOR_STATUS_LIST_NUM: this.aiConfigs.DOOR_STATUS_LIST_NUM,
          Ev_event_code: this.aiConfigs.Ev_event_code,
          Ev_event_cancle_code: this.aiConfigs.Ev_event_cancle_code,
          Block_event_code: this.aiConfigs.Block_event_code,
          Block_event_cancle_code: this.aiConfigs.Block_event_cancle_code,
          Abnormal_event_code: this.aiConfigs.Abnormal_event_code,
          Abnormal_event_cancle_code: this.aiConfigs.Abnormal_event_cancle_code,
          ftp_host: this.aiConfigs.ftp_host,
          ftp_port: this.aiConfigs.ftp_port,
          ftp_user_name: this.aiConfigs.ftp_user_name,
          ftp_user_passwd: this.aiConfigs.ftp_user_passwd,
        };
      }
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:devicename,
        paramsdate:paramsdate,
        devicenum:devicenum,
        devicegroupid:this.devicegroupid,
        orderresult:'失败',
        paramstype:'1',
        parentid:parentid,
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url: '/ai',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.result=this.result+1;
          let param = {
            username:this.username,
            paramsobj:devicename,
            paramsdate:paramsdate,
            devicenum:devicenum,
            devicegroupid:this.devicegroupid,
            orderresult:'成功',
            paramstype:'1',
            parentid:parentid,
            paramsdata:this.paramsdata,
          }
          modifyParamsBatchLog(param).then(res=>{
            if(res&&res.returncode=='0'){
              this.updateParams()
            }
          })
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>