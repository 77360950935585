<template>
  <div class="params">
    <div class="table-btns">
      <a-button type="primary" size="large" @click="setParams" style="float: right;margin-right:3.6%;margin-top: 1%">
        {{'下发当前参数'}}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
<!--      <a-form-model-item label="视频码流" prop="sNightToDay">-->
<!--        <a-select id="status" placeholder="请选择" v-model="id" :disabled="true">-->
<!--          <a-select-option value="">请选择</a-select-option>-->
<!--          <a-select-option :value="0">主码流</a-select-option>-->
<!--          <a-select-option :value="1">子码流</a-select-option>-->
<!--          <a-select-option :value="2">第三码流</a-select-option>-->
<!--        </a-select>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="视频分辨率" prop="sResolution">-->
<!--        <a-input v-model="videoConfigs[id].sResolution" placeholder="数值变大，向下偏移" :disabled="true"></a-input>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="视频帧率" prop="sFrameRate">
        <a-input v-model="videoConfigs[id].sFrameRate" placeholder="数值变大，向下偏移"></a-input>
      </a-form-model-item>
      <a-form-model-item label="视频码率" prop="iMaxRate">
        <a-input v-model="videoConfigs[id].iMaxRate" placeholder="数值变大，向下偏移"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  getDeviceAudioparamValueList, getDevicegroupdevListByCondition,
  getDeviceparamChildLisByParentId, setDeviceAudioparamValueList,
} from "A/ai";
export default {
  name: "videos",
  data() {
    return {
      id:0,
      videoConfigs:[
        {
        sFrameRate: null,
        iMaxRate: null,
        id: 0,
      }],
      devicegroupid:'',
      methodList: [],
      tabKey: '528',
      status:false,
      devicenums:[],
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicegroupid = this.$route.params.devicegroupid||'';
      this.getDevicenum(this.devicegroupid)
    },
    getDevicenum(devicegroupid){
      let params = {
        devicegroupid: devicegroupid,
      }
      getDevicegroupdevListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          for(var i=0;i<res.item.length;i++){
            this.devicenums.push(res.item[i].devicenum)
          }
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParams(){
      for(var i=0;i<this.devicenums.length;i++){
        this.setParamsConfig(this.devicenums[i])
      }
      this.$message.success("操作成功")
    },
    setParamsConfig(devicenum){
      if(this.videoConfigs[0].iMaxRate==null||this.videoConfigs[0].iMaxRate==""){
        this.$delete(this.videoConfigs[0],'iMaxRate')
      }
      if(this.videoConfigs[0].sFrameRate==null||this.videoConfigs[0].sFrameRate==""){
        this.$delete(this.videoConfigs[0],'sFrameRate')
      }
        let params = {
          devicenum: devicenum,
          data: [
            {
              params: {
                methord: 'PUT',
                url:'/video',
                body: this.videoConfigs,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
        }).catch(err => {
          this.hideLoading();
        })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>