<template>
  <div class="params">
    <div class="table-btns">
      <a-button type="primary" size="large" @click="setParams" style="float: right;margin-right:3.6%;margin-top: 1%">
        {{'下发当前参数'}}</a-button>
    </div>
    <a-form-model v-if="LinphoneFlag.linphoneFlag==0" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="通话服务软终端是否开启" prop="linphone_enable">
        <a-input v-model="linphoneConfigs.linphone_enable" placeholder=""></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="分机号" prop="username">-->
<!--        <a-input v-model="linphoneConfigs.username" placeholder=""></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="救援人员姓名" prop="rescuepreson">-->
<!--        <a-input v-model="linphoneConfigs.rescuepreson" placeholder="多个人员请使用英文逗号分隔"></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="救援人员电话" prop="numbers">-->
<!--        <a-input v-model="linphoneConfigs.numbers" placeholder="多个电话请使用英文逗号分隔"></a-input>-->
<!--      </a-form-model-item>-->
      <a-form-model-item label="喇叭音量" prop="master">
        <a-input v-model="linphoneConfigs.master" placeholder="默认80%"></a-input>
      </a-form-model-item>
      <a-form-model-item label="话筒输入量" prop="digital">
        <a-input v-model="linphoneConfigs.digital" placeholder="默认80%"></a-input>
      </a-form-model-item>
    </a-form-model>
    <a-form-model v-if="LinphoneFlag.linphoneFlag==1" ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%"  :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="sip服务器地址" prop="host">
        <a-input v-model="linphoneConfigs.host" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="注册密码" prop="password">
        <a-input type="password" v-model="linphoneConfigs.password" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="监听端口" prop="listen_port">
        <a-input v-model="linphoneConfigs.listen_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="发送端口" prop="send_port">
        <a-input v-model="linphoneConfigs.send_port" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="通话服务模块心跳周期" prop="heartbeat_cycle">
        <a-input v-model="linphoneConfigs.heartbeat_cycle" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="循环注册时间最大值" prop="check_register_rand_time_max">
        <a-input v-model="linphoneConfigs.check_register_rand_time_max" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="循环注册时间最小值" prop="check_register_rand_time_min">
        <a-input v-model="linphoneConfigs.check_register_rand_time_min" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="每次通话服务模块初始化后需要等待时间" prop="after_init_wait_time">
        <a-input v-model="linphoneConfigs.after_init_wait_time" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="每次重新注册后需要等待时间" prop="after_register_wait_time">
        <a-input v-model="linphoneConfigs.after_register_wait_time" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="注册失败时循环尝试注册时间间隔" prop="repeat_register_everytime_wait_time">
        <a-input v-model="linphoneConfigs.repeat_register_everytime_wait_time" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="拨打电话期间循环检测电话是否结束的时间" prop="check_call_done_time">
        <a-input v-model="linphoneConfigs.check_call_done_time" placeholder=""></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import {
  addParamsBatchLog,
  getDevicegroupdevListByCondition, getDevicegroupListByCondition,
  getDeviceparamChildLisByParentId,
  getDeviceparamValueList,
  modifyDeviceInfo, modifyParamsBatchLog,
  setDeviceparamValueList
} from "A/ai";
import {
  getLiftinfoById,
  getLiftinfoListByCondition,
  getUserdepListByCondition,
  modifyLiftinfo,
  modifyUserdep
} from "A/jcgn";
import {getUserByUserid} from "A/login";
import {getCache} from "U/index";
import moment from "moment";

export default {
  name: "linphone",
  props:{
    LinphoneFlag:{
      default: null
    }
  },
  data() {
    return {
      linphoneConfigs:{
        heartbeat_cycle:'',
        linphone_enable:'',
        username:'',
        host:'',
        password:'',
        check_register_rand_time_max:'',
        check_register_rand_time_min:'',
        after_init_wait_time:'',
        after_register_wait_time:'',
        repeat_register_everytime_wait_time:'',
        listen_port:'',
        send_port:'',
        rescuepreson:'',
        numbers:'',
        check_call_done_time:'',
        gpio_enable:'',
        gpio_active_enable:'',
        master:'',
        digital:'',
      },
      paramsdata:'',
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      liftid:'',
      methodList: [],
      tabKey: '88',
      status:false,
      userdepid:'',
      userdepname:'',
      levelid:'',
      deptype:'',
      devicegroupid:'',
      devicegroupname:'',
      devicenums:[],
      devicenames:[],
      result:0,
      parentid:'',
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.devicegroupid = this.$route.params.devicegroupid||'';
      this.getDevicenum(this.devicegroupid);
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    getDevicenum(devicegroupid){
      let params = {
        devicegroupid: devicegroupid,
      }
      getDevicegroupdevListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          for(var i=0;i<res.item.length;i++){
            this.devicenums.push(res.item[i].devicenum)
            this.devicenames.push(res.item[i].devicename)
            this.devicegroupname=res.item[0].devicegroupname
          }
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '88';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    updateParams(){
      let param = {
        paramsid:this.parentid,
        orderresult:(this.result / this.devicenums.length * 100).toFixed(2) + "%",
      }
      modifyParamsBatchLog(param)
    },
    setParams(){
      let param = {
        username:this.username,
        paramsobj:this.devicegroupname,
        paramsdate:moment(new Date()).format("YYYYMMDDHHmmss"),
        devicenum:"",
        devicegroupid:"0",
        orderresult:"0.00%",
        paramstype:'0',
      }
      addParamsBatchLog(param).then(res=>{
        if(res&&res.returncode=='0'){
          this.result=0;
          this.parentid=res.item[0].paramsid
          for(var i=0;i<this.devicenums.length;i++){
            this.setParamsConfig(this.devicenums[i],this.devicenames[i],res.item[0].paramsid)
          }
          this.$message.success("操作成功")
        }
      })
    },
    setParamsConfig(devicenum,devicename,parentid){
      this.paramsdata='';
      let paramsBody={}
      if(this.LinphoneFlag.linphoneFlag==0){
        paramsBody = {
          linphone_enable: this.linphoneConfigs.linphone_enable,
          master: this.linphoneConfigs.master,
          digital: this.linphoneConfigs.digital,
        }
        if(this.linphoneConfigs.linphone_enable==null||this.linphoneConfigs.linphone_enable==""){this.$delete(paramsBody,'linphone_enable')}else{this.paramsdata=this.paramsdata+"通话服务软终端是否开启,"+this.linphoneConfigs.linphone_enable+","}
        if(this.linphoneConfigs.master==null||this.linphoneConfigs.master==""){this.$delete(paramsBody,'master')}else{this.paramsdata=this.paramsdata+"喇叭音量,"+this.linphoneConfigs.master+","}
        if(this.linphoneConfigs.digital==null||this.linphoneConfigs.digital==""){this.$delete(paramsBody,'digital')}else{this.paramsdata=this.paramsdata+"话筒输入量,"+this.linphoneConfigs.digital+","}
      }else{
          paramsBody = {
          heartbeat_cycle : this.linphoneConfigs.heartbeat_cycle,
          host : this.linphoneConfigs.host,
          password : this.linphoneConfigs.password,
          check_register_rand_time_max : this.linphoneConfigs.check_register_rand_time_max,
          check_register_rand_time_min : this.linphoneConfigs.check_register_rand_time_min,
          after_init_wait_time : this.linphoneConfigs.after_init_wait_time,
          after_register_wait_time : this.linphoneConfigs.after_register_wait_time,
          repeat_register_everytime_wait_time : this.linphoneConfigs.repeat_register_everytime_wait_time,
          listen_port : this.linphoneConfigs.listen_port,
          send_port : this.linphoneConfigs.send_port,
          check_call_done_time : this.linphoneConfigs.check_call_done_time,
          gpio_enable : this.linphoneConfigs.gpio_enable,
          gpio_active_enable : this.linphoneConfigs.gpio_active_enable,
        }
      }
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:devicename,
        paramsdate:paramsdate,
        devicenum:devicenum,
        devicegroupid:this.devicegroupid,
        orderresult:'失败',
        paramstype:'1',
        parentid:parentid,
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: devicenum,
        data: [
          {
            modeltype: this.devicemoduletype,
            modelnum: this.modelnum,
            params: {
              methord: 'PUT',
              url:'/linphone',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.result=this.result+1;
          let param = {
            username:this.username,
            paramsobj:devicename,
            paramsdate:paramsdate,
            devicenum:devicenum,
            devicegroupid:this.devicegroupid,
            orderresult:'成功',
            paramstype:'1',
            parentid:parentid,
            paramsdata:this.paramsdata,
          }
          modifyParamsBatchLog(param).then(res=>{
            if(res&&res.returncode=='0'){
              this.updateParams()
            }
          })
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>