<template>
  <div class="formlist-container">
    <template v-for="item in datalist">
      <div v-if="item.children" :key="item.key" style="border: solid 1px #ddd;padding-bottom: 10px;margin: 10px 0;">
        <h3 style="padding-left: 20px;">{{item.name}}</h3>
        <!-- <a-form-model-item :label="item.name"></a-form-model-item> -->
        <paramsFormlist :datalist="item.children"></paramsFormlist>
      </div>
      <a-form-model-item v-else :key="item.key" :label="item.name">
        <a-input v-model="item.value" />
      </a-form-model-item>
    </template>
  </div>
</template>
<script>
import paramsFormlist from './paramsFormlist'
export default {
  name: 'paramsFormlist',
  components: {
    paramsFormlist
  },
  props: {
    datalist: {
      default() {
        return []
      }
    }
  }
}
</script>