<template>
  <div class="params">
    <div class="table-btns">
      <a-button type="primary" size="large" @click="setParams" style="float: right;margin-right:3.6%;margin-top: 1%">
        {{'下发当前参数'}}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="日夜间模式设置" prop="sNightToDay">
        <a-select id="status" placeholder="请选择" v-model="displayConfigs.nightToDay.sNightToDay">
          <a-select-option value="">请选择</a-select-option>
          <a-select-option value="day">白天</a-select-option>
          <a-select-option value="night">黑夜</a-select-option>
          <a-select-option value="auto">自动</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="亮度" prop="iBrightness">
        <a-input v-model="displayConfigs.imageAdjustment.iBrightness" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="对比度" prop="iContrast">
        <a-input v-model="displayConfigs.imageAdjustment.iContrast" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="饱和度" prop="iSaturation">
        <a-input v-model="displayConfigs.imageAdjustment.iSaturation" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
      <a-form-model-item label="锐度" prop="iSharpness">
        <a-input v-model="displayConfigs.imageAdjustment.iSharpness" placeholder="请填写0-100之间的数字"></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  getDeviceAudioparamValueList, getDevicegroupdevListByCondition,
  getDeviceparamChildLisByParentId, setDeviceAudioparamValueList, setDeviceparamValueList,
} from "A/ai";
export default {
  name: "display",
  data() {
    return {
      displayConfigs:{
        imageAdjustment: {
          iSharpness: null,
          iSaturation: null,
          iBrightness: null,
          iContrast: null,
        },
        nightToDay: {
          sNightToDay: "",
        }
      },
      methodList: [],
      tabKey: '527',
      status:false,
      devicegroupid:'',
      devicenums:[],
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicegroupid = this.$route.params.devicegroupid||'';
      console.log(this.devicegroupid)
      this.getDevicenum(this.devicegroupid)
    },
    getDevicenum(devicegroupid){
      let params = {
        devicegroupid: devicegroupid,
      }
      getDevicegroupdevListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          for(var i=0;i<res.item.length;i++){
            this.devicenums.push(res.item[i].devicenum)
          }
        }
      })
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParams(){
      for(var i=0;i<this.devicenums.length;i++){
        this.setParamsConfig(this.devicenums[i])
      }
      this.$message.success("操作成功")
    },
    setParamsConfig(devicenum){
      if(this.displayConfigs.imageAdjustment.iSharpness==null||this.displayConfigs.imageAdjustment.iSharpness==""){
        this.$delete(this.displayConfigs.imageAdjustment,'iSharpness')
      }
      if(this.displayConfigs.imageAdjustment.iSaturation==null||this.displayConfigs.imageAdjustment.iSaturation==""){
        this.$delete(this.displayConfigs.imageAdjustment,'iSaturation')
      }
      if(this.displayConfigs.imageAdjustment.iBrightness==null||this.displayConfigs.imageAdjustment.iBrightness==""){
        this.$delete(this.displayConfigs.imageAdjustment,'iBrightness')
      }
      if(this.displayConfigs.imageAdjustment.iContrast==null||this.displayConfigs.imageAdjustment.iContrast==""){
        this.$delete(this.displayConfigs.imageAdjustment,'iContrast')
      }
      if(this.displayConfigs.nightToDay.sNightToDay==null||this.displayConfigs.nightToDay.sNightToDay==""){
        this.$delete(this.displayConfigs.nightToDay,'sNightToDay')
      }
        let params = {
          devicenum: devicenum,
          data: [
            {
              params: {
                methord: 'PUT',
                url:'/image/0',
                body: this.displayConfigs,
              }
            }
          ]
        };
        this.showLoading();
        setDeviceAudioparamValueList(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0' ) {
            if(this.displayConfigs.nightToDay.sNightToDay!=''||this.displayConfigs.nightToDay.sNightToDay!=null){
              let param={
                devicenum: this.devicenum,
                data: [
                  {
                    params: {
                      methord: 'PUT',
                      url:'/iot',
                      body: {Reboot:'1'},
                    }
                  }
                ]
              }
              setDeviceparamValueList(param)
            }
          }
        }).catch(err => {
          this.hideLoading();
        })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>