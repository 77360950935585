import { render, staticRenderFns } from "./paramsConfig.vue?vue&type=template&id=07fd4fee&scoped=true&"
import script from "./paramsConfig.vue?vue&type=script&lang=js&"
export * from "./paramsConfig.vue?vue&type=script&lang=js&"
import style0 from "./paramsConfig.vue?vue&type=style&index=0&id=07fd4fee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07fd4fee",
  null
  
)

export default component.exports