import store from '@/store'
import http from '@/utils/http'

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

export function login(params) {
  return http({
    url: `${preApi}/login`,
    method: "post",
    data: params
  })
}
export function logout() {
  return http({
    url: `${preApi}/user/logout`,
    method: "post",
    data: {}
  })
}

export function getUserByUserid(params) {
  return http({
    url: `${preApi}/user/getUserByUserid`,
    method: "post",
    data: params
  })
}
