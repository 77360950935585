<template>
  <div class="params">
    <div class="table-btns">
      <a-button type="primary" size="large" @click="setParams" style="float: right;margin-right:3.6%;margin-top: 1%">
        {{'下发当前参数'}}</a-button>
    </div>
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" style="margin-top: 5%" :label-col="{span:9}" :wrapper-col="{span:14}">
      <a-form-model-item label="iAutoDst" prop="iAutoDst">
        <a-input v-model="ntpConfig.iAutoDst" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="iAutoMode" prop="iAutoMode">
        <a-input v-model="ntpConfig.iAutoMode" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="sTimeZoneFileDst" prop="sTimeZoneFileDst">
        <a-input v-model="ntpConfig.sTimeZoneFileDst" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="sTimeZoneFile" prop="sTimeZoneFile">
        <a-input v-model="ntpConfig.sTimeZoneFile" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="sTimeZone" prop="sTimeZone">
        <a-input v-model="ntpConfig.sTimeZone" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="ntp地址" prop="sNtpServers">
        <a-input v-model="ntpConfig.sNtpServers" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="iRefreshTime" prop="iRefreshTime">
        <a-input v-model="ntpConfig.iRefreshTime" placeholder=""></a-input>
      </a-form-model-item>
      <a-form-model-item label="id" prop="id">
        <a-input v-model="ntpConfig.id" placeholder=""></a-input>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import {
  addParamsBatchLog,
  getDeviceAudioparamValueList, getDevicegroupdevListByCondition, getDeviceinfoListByCondition,
  getDeviceparamChildLisByParentId, modifyParamsBatchLog, setDeviceAudioparamValueList, setDeviceparamValueList,
} from "A/ai";
import moment from "moment";
import {getCache} from "U/index";
export default {
  name: "networkNtp",
  data() {
    return {
      methodList: [],
      ntpConfig:{
        iAutoDst: 0,
        iAutoMode: 1,
        sTimeZoneFileDst: "posix/Asia/Shanghai",
        sTimeZoneFile: "posix/Etc/GMT-8",
        sTimeZone: "ChinaStandardTime-8",
        sNtpServers: "ntp.ntsc.ac.cn time1.cloud.tencent.com",
        iRefreshTime: 60,
        id: 0
      },
      paramsdata:'',
      devicemoduletype: '',
      devicenum: '',
      modelnum: '',
      liftid:'',
      tabKey: '535',
      status:false,
      userdepid:'',
      userdepname:'',
      levelid:'',
      deptype:'',
      devicegroupid:'',
      devicegroupname:'',
      devicenums:[],
      devicenames:[],
      result:0,
      parentid:'',
    }
  },
  watch: {
    devicemoduletype(val) {
      if (val) {
        this.getMethods();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.devicemoduletype = this.$route.params.devicemoduletype||'1';
      this.devicenum = this.$route.params.devicenum||'';
      this.modelnum = this.$route.params.modelnum||'';
      this.devicegroupid = this.$route.params.devicegroupid||'';
      this.getDevicenum(this.devicegroupid);
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    getMethods() {
      this.methodList = [];
      this.tabKey = '20';
      this.paramsList = [];
      let params = {
        parentid: this.devicemoduletype
      };
      this.showLoading();
      getDeviceparamChildLisByParentId(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.methodList = res.item||[];
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParams(){
      let param = {
        username:this.username,
        paramsobj:this.devicegroupname,
        paramsdate:moment(new Date()).format("YYYYMMDDHHmmss"),
        devicenum:"",
        devicegroupid:"0",
        orderresult:"0.00%",
        paramstype:'0',
      }
      addParamsBatchLog(param).then(res=>{
        if(res&&res.returncode=='0'){
          this.result=0;
          this.parentid=res.item[0].paramsid
          for(var i=0;i<this.devicenums.length;i++){
            this.setParamsConfig(this.devicenums[i],this.devicenames[i],res.item[0].paramsid)
          }
          this.$message.success("操作成功")
        }
      })
    },
    updateParams(){
      let param = {
        paramsid:this.parentid,
        orderresult:(this.result / this.devicenums.length * 100).toFixed(2) + "%",
      }
      modifyParamsBatchLog(param)
    },
    setParamsConfig(devicenum,devicename,parentid){
      this.paramsdata='';
      let paramsBody = this.ntpConfig
      this.paramsdata=this.paramsdata+"iAutoDst,"+this.ntpConfig.iAutoDst+","
      this.paramsdata=this.paramsdata+"iAutoMode,"+this.ntpConfig.iAutoMode+","
      this.paramsdata=this.paramsdata+"sTimeZoneFileDst,"+this.ntpConfig.sTimeZoneFileDst+","
      this.paramsdata=this.paramsdata+"sTimeZoneFile,"+this.ntpConfig.sTimeZoneFile+","
      this.paramsdata=this.paramsdata+"sTimeZone,"+this.ntpConfig.sTimeZone+","
      this.paramsdata=this.paramsdata+"ntp地址,"+this.ntpConfig.sNtpServers+","
      this.paramsdata=this.paramsdata+"iRefreshTime,"+this.ntpConfig.iRefreshTime+","
      this.paramsdata=this.paramsdata+"id,"+this.ntpConfig.id+","
      let paramsdate=moment(new Date()).format("YYYYMMDDHHmmss")
      let param = {
        username:this.username,
        paramsobj:devicename,
        paramsdate:paramsdate,
        devicenum:devicenum,
        devicegroupid:this.devicegroupid,
        orderresult:'失败',
        paramstype:'1',
        parentid:parentid,
        paramsdata:this.paramsdata,
      }
      addParamsBatchLog(param)
      let params = {
        devicenum: devicenum,
        data: [
          {
            params: {
              methord: 'PUT',
              url:'/network-ntp',
              body: paramsBody,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceAudioparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.result=this.result+1;
          let param = {
            username:this.username,
            paramsobj:devicename,
            paramsdate:paramsdate,
            devicenum:devicenum,
            devicegroupid:this.devicegroupid,
            orderresult:'成功',
            paramstype:'1',
            parentid:parentid,
            paramsdata:this.paramsdata,
          }
          modifyParamsBatchLog(param).then(res=>{
            if(res&&res.returncode=='0'){
              this.updateParams()
            }
          })
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    getDevicenum(devicegroupid){
      let params = {
        devicegroupid: devicegroupid,
      }
      getDevicegroupdevListByCondition(params).then(res => {
        if (res && res.returncode == '0') {
          for(var i=0;i<res.item.length;i++){
            this.devicenums.push(res.item[i].devicenum)
            this.devicenames.push(res.item[i].devicename)
            this.devicegroupname=res.item[0].devicegroupname
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.params{
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  height: 730px;
  //float: right;
  //margin-left: 0.5%;
  background-color: white;
  border-radius: 5px;
}
</style>